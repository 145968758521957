<template>
    <div>
        <!--      <IndexCom></IndexCom>-->
        <div class="ci-header">
            <Carousel v-model="value1" :dots="indexList.config.length>1?'inside':'none'"
                      :arrow="indexList.config.length>1?'hover':'none'" :autoplay="indexList.config.length>1"
                      :autoplay-speed="speed" loop
                      style="height: 610px">
                <CarouselItem style="height: 610px" v-for="(item,index) in indexList.config" :key="item.order + index">
                    <a :href="item.target" target="_blank"><img :src="item.pic" width="100%" height="100%"/></a>
                </CarouselItem>
            </Carousel>

            <div class="ci-des">
                <div class="ci-des-body">
                    <div class="ci-des-body-fontB">
                        专利成果：专利<span class="ci-des-body-fontA" id="count0">330</span>万+条 成果<span class="ci-des-body-fontA" id="count1">82</span>万条
                    </div>
                    <div class="ci-des-body-fontB">
                        <span style="margin-right: 14px">企校通：</span>高校<span class="ci-des-body-fontA" id="count2">2688</span>所 签订协议<span class="ci-des-body-fontA">20</span>所
                    </div>
                </div>
                <div class="ci-des-body" style="border-right: 1px solid rgba(255, 255, 255, 0.1)">
                    <div class="ci-des-body-fontB">企业库： 入库企业<span class="ci-des-body-fontA" id="count3">1296</span>家</div>
                    <div class="ci-des-body-fontB">企服通： 累计注册<span class="ci-des-body-fontA" id="count4">174</span>家</div>
                </div>
                <div class="ci-des-body" style="border-right: 1px solid rgba(255, 255, 255, 0.1)">
                    <div class="ci-des-body-fontB">企业服务评测报告： 出具报告<span class="ci-des-body-fontA" id="count5">141</span>份企业</div>
                    <div class="ci-des-body-fontB"><span style="margin-right: 63px">需求匹配：</span>出具匹配报告<span class="ci-des-body-fontA" id="count6">260</span>项</div>
                </div>
                <div class="ci-des-body">
                    <div class="ci-des-body-fontB">托管服务： 托管专利<span class="ci-des-body-fontA" id="count7">180</span>项</div>
                    <div class="ci-des-body-fontB">科技型企业申报辅助：<span class="ci-des-body-fontA" style="margin-left: 14px" id="count8">2</span>项</div>
                </div>
            </div>


        </div>
        <demand-information></demand-information>
        <component v-for="(item,index) in indexList.floors" :data="item.config"
                   :is="getComponent(item.config.floorType)"
                   :key="index + 1" v-if="item.config.labels && item.config.labels.length > 0"></component>

    </div>
</template>

<script>
    import $ from "jquery";
    import IndexCom from "@/components/IndexCom";
    import {
        getIndexCollegeData,
        getIndexFloorDetail,
        getIndexRegisterData,
        getIndexUrl
    } from "../../plugins/api/indexApi";
    import {formatNum} from "../../plugins/tools/util";

    export default {
        name: "ComponentIndex",
        componentList: [],
        components: {
            IndexCom,
            "demandInformation": () => import("@/components/index/homePageComponent/demandAndInformation"),//需求大厅头条资讯
            "enterpriseSchoolCase": () => import("@/components/index/homePageComponent/enterpriseSchoolCase"),//企效通案例
            "hotPatent": () => import("@/components/index/homePageComponent/hotPatent"),//热门专利推荐
            "racePolicy": () => import("@/components/index/homePageComponent/raceAndPolicy"),//科技竞赛科技政策
            "hotService": () => import("@/components/index/homePageComponent/hotService"),//热门服务推荐
            "expertRecommend": () => import("@/components/index/homePageComponent/expertRecommend"),//科研专家推荐
            "testAndNews": () => import("@/components/index/homePageComponent/testAndNews"),//企业体检科技新闻
            "universityStore": () => import("@/components/index/homePageComponent/universityStore"),//高校旗舰店
            "wikiAndReport": () => import("@/components/index/homePageComponent/wikiAndReport"),//知产百科媒体报道
            "enterpriseList": () => import("@/components/index/homePageComponent/enterpriseList")//企业库

        },
        data() {
            return {
                speed: 5000,
                value1: 0,
                indexList: [],//首页数据
                registerData: {},//首页头部注册信息
                collegeData: {},//首页头部学校信息
                footerList: [],//底部跳转链接
                enterpriseListData: {
                    config: {
                        floorType: "Enterprise_List",
                        labels: [
                            {id: "1"}
                        ],
                        name: "企业库"
                    }
                }
            };
        },
        mounted() {
            this.getIndexShowDetail();
            this.getRegisterData();
            this.getCollegeData();
            this.countjs()
        },
        methods: {
            //数字滚动
            countjs(){
                let options = {
                    lastNumber:6666,
                    duration:5000,
                    easing:'swing',  //慢快慢
                }
                this.$nextTick(() => {
                    setTimeout(()=>{
                        for(let i = 0;i<8;i++){
                            console.log(i)
                            this.countsetting($('#count'+i),options,$('#count'+i).html())
                        }
                    },1000)
                });
            },
            countsetting(obj,options,lastNumber){
                var defaults={
                    lastNumber:100,
                    duration:2000,
                    easing:'swing'  //swing(默认 : 缓冲 : 慢快慢)  linear(匀速的)
                };
                var opts=$.extend({}, defaults, options);
                obj.animate({
                    num : "numberRock",
                    // width : 300,
                    // height : 300,
                },{
                    duration : opts.duration,
                    easing : opts.easing,
                    complete : function(){
                        console.log("success");
                    },
                    step : function(a,b){  //可以检测我们定时器的每一次变化
                        //console.log(a);
                        //console.log(b.pos);   //运动过程中的比例值(0~1)
                        lastNumber = lastNumber || opts.lastNumber
                        obj.html(parseInt(b.pos * lastNumber));
                    }
                });
            },
            //获取首页展示数据
            async getIndexShowDetail() {
                const json = await getIndexFloorDetail();
                if (json && json.code === 0) {
                    this.indexList = json.result;
                    // 写死专家库
                    this.indexList.floors.splice(4, 0, this.enterpriseListData)
                }
            },
            //获取指定的component
            getComponent(type) {
                let component = "";
                const regName = [
                    {type: "PATENT_GOODS_FLOOR", component: "hotPatent"},
                    // { type: "MAIN_POLICY_FLOOR", component: "racePolicy" },
                    {type: "SERVICE_GOODS_FLOOR", component: "hotService"},
                    {type: "SHOP_EXPERT_FLOOR", component: "expertRecommend"},
                    // { type: "MAIN_NEWS_FLOOR", component: "testAndNews" },
                    {type: "SHOP_FLOOR", component: "universityStore"},
                    {type: "MAIN_PROPERTY_MEDIA_FLOOR", component: "wikiAndReport"},
                    {type: "Enterprise_List", component: "enterpriseList"}
                    // { type: "SINGLE_AD_FLOOR", component: "enterpriseSchoolCase" }
                ];
                regName.map(item => {
                    if (type.indexOf(item.type) !== -1) {
                        component = item.component;
                        return;
                    }
                });
                return component;
            },
            async getRegisterData() {
                const json = await getIndexRegisterData();
                if (json && json.code === 0) {
                    this.registerData = json.result;
                }
            },
            async getCollegeData() {
                const json = await getIndexCollegeData();
                if (json && json.code === 0) {
                    this.collegeData = json.result;
                }
            },
            formatNum(num) {
                return formatNum(num);
            }


        }
    };
</script>

<style scoped lang="scss">

    .qxt_content .buzhou span {
        font-size: 16px;
        font-family: "Microsoft YaHei";
        color: rgb(255, 255, 255);
        height: 45px;
        line-height: 45px;
        display: inline-block;
        margin-right: 20px;

    }


    .bottom_second_content span {
        height: 35px;
        line-height: 35px;
        margin-right: 20px;
        font-size: 14px;
        color: rgb(200, 200, 200);
    }


    .bottom_first_content ul li:hover {
        cursor: pointer;
    }

    .bottom_first_content ul li {
        display: inline-block !important;
        width: auto;
        height: 75px;
        line-height: 60px;
        margin-right: 50px;
        font-size: 16px;
        font-family: "Microsoft YaHei";
        color: white;
    }


    .rwjs_sub div {
        margin-bottom: 10px;
    }


    .rmzl_title_price .desc {
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;;
        overflow: hidden;
    }


    .zl_classify span {
        margin-right: 20px;
        line-height: 30px;
    }


    .xuqiu_content ul, .toutiao_content ul {
        list-style: none;
    }

    .xuqiu_content ul li {
        display: flex;
        height: 75px;
        line-height: 75px;
    }

    .toutiao_content ul li {
        height: 50px;
        width: 450px;
        line-height: 50px;
        border-bottom: 1px solid #f5f5f5;
        margin: 0 auto;
        box-sizing: border-box;
    }


    .tixing div {
        padding-left: 20px;
        color: white;
    }

    .top-four span {
        margin-left: 80px;
        cursor: pointer;
    }

    .top-four div:first-child span {
        margin-left: 40px;
    }

    .top-four span:hover {
        cursor: pointer;
    }


    .choice span {
        margin-right: 10px;
    }

    .choice span:hover {
        cursor: pointer;
    }


    .right_input .input input {
        border-width: 2px;
        border-color: rgb(255, 106, 0);
        border-style: solid;
        background-color: rgb(255, 255, 255);
        width: 700px;
        height: 44px;
    }

    .right_input .input button {
        background-color: rgb(255, 106, 0);
        width: 130px;
        height: 44px;
        color: white;
        vertical-align: bottom;
        border: none;
    }

    .input button {
        cursor: pointer;
    }

    .top-third .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .top-third .content {
        width: 1200px;
        margin: 0 auto;
        height: 100%;
    }

    .tab ul {
        list-style: none;
        width: 850px;
    }

    .tab ul li {
        display: inline-block;
        font-size: 13px;
        font-family: "Microsoft YaHei";
        padding: 0 20px;
        color: rgb(102, 102, 102);
        position: relative;
    }

    .content .tab {
        width: 850px;
    }


    .content .platform {
        position: absolute;
        width: 170px;
        font-size: 14px;
        font-family: "Microsoft YaHei";
        color: rgb(102, 102, 102);
    }

    .content div .border {
        position: absolute;
        left: 93px;
        width: 1px;
        height: 12px;
        background-color: rgb(127, 127, 127);
        top: 14px;
    }


    .content .zxsc {
        position: absolute;
        left: 190px;
        width: 160px;
    }

    .ci-header {
        position: relative;
    }

    .ci-des {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 99px;
        right: 0;
        z-index: 99;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .ci-des-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 25px 30px;
        width: fit-content;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        cursor: pointer;
    }

    .ci-des-body:hover {
        background-color: rgba(255, 255, 255, 0.12);
    }


    .ci-des-body-fontA {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC, serif;
        font-weight: 600;
        color: #F68C2F;
    }

    .ci-des-body-fontB {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #FFFFFF;
    }
</style>
